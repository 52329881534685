
import { Form as Validation } from 'vee-validate'
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { useRoute } from 'vue-router'
import SignWithButtons from '@/components/pages/auth/SignWithButtons.vue'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'

export default defineComponent({
  components: {
    TmFormLine,
    TmButton,
    SignWithButtons,
    Validation,
  },
  setup() {
    const route = useRoute()

    const email = ref(route.params.email || '')
    const password = ref()
    const country = ref(phoneCountryVariant[0])
    const firstName = ref()
    const lastName = ref()

    return {
      firstName,
      lastName,
      country,
      email,
      password,
    }
  },
})
